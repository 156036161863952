import React from "react";

interface ButtonProps {
    children?: JSX.Element | string,
    iconRight?: JSX.Element,
    iconLeft?: JSX.Element,
    text?: string,
    loading?: boolean,
    disabled?: boolean,
    onClick?: React.MouseEventHandler,
    className?: string,
	overrideClassName?: boolean,
    textClassName?: string
}

const Button = ({ children, iconRight, iconLeft, text, loading, disabled, onClick, className, overrideClassName, textClassName }: ButtonProps) => {
	let classes = "flex items-center justify-center disabled:cursor-not-allowed shadow-lg tracking-tight " +
		"rounded-md bg-purple-600 hover:bg-purple-700 text-white font-semibold text-lg px-4 py-1";

	if (className) {
		if (overrideClassName)
			classes = className;
		else
			classes = `${classes} ${className}`;
	}

	return (
		<button disabled={disabled ? disabled : loading} onClick={onClick} className={classes + " relative"}>
			{children ? (
				<div className={`${loading ? "opacity-0" : ""} ${textClassName ?? ""}`}>
					{children}
				</div>
			) : (
				<div className={`flex items-center justify-center ${textClassName ?? ""} ${loading ? "opacity-0" : ""}`}>
					{iconLeft}
					{text}
					{iconRight}
				</div>
			)}
			{loading && (
				<div className="justify-center flex absolute">
					<svg className="animate-spin h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
						<circle
							className="opacity-25" cx="12" cy="12" r="10"
							stroke="currentColor"
							strokeWidth="4"
						/>
						<path
							className="opacity-75" fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
							7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						/>
					</svg>
				</div>
			)}
		</button>
	);
};

export default Button;
