import React, { useEffect, useState } from "react";
import { Header, Button, Spinner, Input } from "../components";
import { ArrowRightOnRectangleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ActionRequest, ErrorResponse, QueueEntry, QueueResponse } from "../types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Landing = () => {
	const [queue, setQueue] = useState<QueueEntry[] | null>(null);
	const [queueError, setQueueError] = useState<string | null>(null);
	const [joinError, setJoinError] = useState<string | null>(null);
	const [leaveError, setLeaveError] = useState<string | null>(null);
	const [queueLoading, setQueueLoading] = useState(true);
	const [joinLoading, setJoinLoading] = useState(false);
	const [leaveLoading, setLeaveLoading] = useState<number | null>(null);
	const [reloadQueue, setReloadQueue] = useState(0);

	const [joinName, setJoinName] = useState("");

	const handleJoinQueue = async () => {
		setJoinError(null);

		if (!joinName) {
			setJoinError("Please provide a name to join the queue");
			return;
		}

		setJoinLoading(true);

		try {
			const joinRequest: ActionRequest = {
				name: joinName
			};

			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/join`, {
				method: "POST",
				headers: {
					"content-type": "application/json"
				},
				body: JSON.stringify(joinRequest)
			});

			if (response.ok)
				setReloadQueue(Math.random());
			else {
				const body = await response.json() as ErrorResponse;

				setJoinError(body.message);
			}
		} catch (_) {
			setJoinError("An unknown error occurred");
		}

		setJoinLoading(false);
	};

	const handleLeaveQueue = async (name: string, index: number) => {
		setLeaveError(null);
		setLeaveLoading(index);

		try {
			const leaveRequest: ActionRequest = {
				name
			};

			const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/leave`, {
				method: "DELETE",
				headers: {
					"content-type": "application/json"
				},
				body: JSON.stringify(leaveRequest)
			});

			if (response.ok)
				setReloadQueue(Math.random());
			else {
				const body = await response.json() as ErrorResponse;

				setLeaveError(body.message);
			}
		} catch (_) {
			setLeaveError("An unknown error occurred");
		}

		setLeaveLoading(null);
	};

	useEffect(() => {
		(async () => {
			setQueueError(null);
			setQueueLoading(true);

			try {
				const response = await fetch(process.env.REACT_APP_BASE_API_URL);

				if (response.ok) {
					const body = await response.json() as QueueResponse;
					setQueue(body.queue);
				} else {
					const body = await response.json() as ErrorResponse;

					setQueueError(body.message);
				}
			} catch (_) {
				setQueueError("An unknown error occurred");
			}

			setQueueLoading(false);
		})();
	}, [reloadQueue]);

	return (
		<div className="m-8 select-none">
			<Header row={
				<>
					{queueLoading ? (
						<Spinner />
					) : queueError ? (
						<p className="mt-3 font-bold font-mono text-red-600 text-sm flex gap-2 items-center">
							<ExclamationCircleIcon className="w-5 h-5 inline-block" />
							Loading Queue: {queueError}
						</p>
					) : (
						<>
							<div className="flex gap-2">
								<Input type="text" placeholder="Name..." onChange={(e) => setJoinName(e.target.value)} value={joinName} />
								<Button
									loading={joinLoading}
									text={queue?.length === 0 ? "Use Dev": "Join Queue"}
									onClick={handleJoinQueue}
									textClassName="font-mono"
								/>
							</div>

							{joinError && (
								<p className="mt-3 font-bold font-mono text-red-600 text-sm flex gap-2 items-center">
									<ExclamationCircleIcon className="w-5 h-5 inline-block" />
									{joinError}
								</p>
							)}
						</>
					)}
				</>
			} />

			{!queueLoading && !queueError && queue !== null && (
				<div className="mt-12">
					<h3 className="font-mono text-white font-bold text-2xl mb-4">
						Queue
					</h3>
					{leaveError && (
						<p className="mt-3 font-bold font-mono text-red-600 text-sm flex gap-2 items-center mb-4">
							<ExclamationCircleIcon className="w-5 h-5 inline-block" />
							Leaving Queue: {leaveError}
						</p>
					)}
					<div className="flex flex-col gap-y-3">
						{queue.length === 0 && (
							<p className="text-lg text-purple-500 font-mono font-semibold">
								Looks like the queue is empty.<br /> Dev should be free to use!
							</p>
						)}
						{queue.map((entry, index) => {
							return (
								<div key={entry.name} className="border-2 border-neutral-700 rounded-lg p-3 max-w-lg flex justify-between">
									<div className="flex gap-2 items-center">
										<p className="font-mono text-white">
											{entry.name}
										</p>
										{index === 0 && (
											<span className="px-2 rounded-md text-sm bg-green-600 font-bold text-white">
												Using Dev ({dayjs.duration(dayjs().diff(dayjs(entry.usingAt))).humanize()})
											</span>
										)}
									</div>
									<Button
										textClassName="text-white"
										onClick={() => handleLeaveQueue(entry.name, index)}
										className="bg-red-500 hover:bg-red-600 px-1"
										loading={leaveLoading === index}
									>
										<ArrowRightOnRectangleIcon className="w-6 h-6" />
									</Button>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default Landing;
