import React from "react";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	overrideClassName?: boolean
}

const Input = ({ className, overrideClassName, ...rest }: InputProps) => {
	let classes = "flex items-center justify-center disabled:cursor-not-allowed tracking-tight " +
		"rounded-md bg-neutral-800 border-2 border-neutral-700 text-white font-semibold focus:outline-none " +
		"focus:border-purple-500 transition text-lg px-3 py-1";

	if (className) {
		if (overrideClassName)
			classes = className;
		else
			classes = `${classes} ${className}`;
	}

	return (
		<input className={classes} {...rest} />
	);
};

export default Input;
