import React from "react";
import { Link } from "react-router-dom";

interface HeaderProps {
	row?: JSX.Element
}

const Header = (props: HeaderProps) => {
	const { row } = props;

	return (
		<>
			<Link to="/">
				<h1 className="text-4xl font-mono text-white font-bold transition duration-500 hover:text-purple-500">
					DevBot
				</h1>
			</Link>
			<h5 className="font-mono italic text-neutral-400 font-bold">
				See who is using dev below!
			</h5>

			{row ? <div className="mt-4">{row}</div> : null}
		</>
	);
};

export default Header;
